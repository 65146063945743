import React from 'react';
import logo from './duckavatar.jpg';
import middlefinger from './middlefinger.svg';
import './App.scss';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";


interface IProps {
}

interface IState {
  content?: string;
}

export default function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <Router>
          <div>
            <Link to="/">Home</Link> | <Link to="/about">About</Link>
          </div>

          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/about">
              <About />
            </Route>
            <Route component={NoMatch} />
          </Switch>
        </Router>
      </header>
    </div>
  );
}

function Home() {
  return (
    <div>
      <p>
        This is the personal webpage of <code>James Alan Nguyen</code>, now with
        added SSL!
      </p>
      <p>
        As you can see, there is <em>still</em> nothing here yet...
      </p>
    </div>
  );
}

function NoMatch() {
  return (
    <div>
      <img src={middlefinger} className="App-logo" alt="404" />
    </div>
  );
}


class About extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      content: ""
    };
  }

  componentDidMount() {
    fetch("index-old.php")
    .then(res => res.text())
    .then((result) => {
      this.setState({
        content: result
      });
    })
  }

  render() {
    //return this.state.content;
    return <div dangerouslySetInnerHTML= { { __html: this.state.content || ""} } />
  }
}